<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('modrForm')"
        @cancel="cancel">
    </add-header>
    <el-form ref="modrForm" :rules="rules" :model="modrForm" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="订单编号" prop="modr_no">
            <el-input v-model="modrForm.modr_no" maxlength="30" clearable show-word-limit placeholder="请填写订单编号">
            </el-input>
          </el-form-item>
          <el-form-item label="交货时间" prop="modr_date">
            <el-date-picker
                v-model="modrForm.modr_date"
                type="date"
                placeholder="请选择交货时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购方" prop="cptt_id">
            <el-select v-model="modrForm.cptt_id" placeholder="请选择采购方" clearable>
              <el-option
                  v-for="item in cpttFctrGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="总金额" prop="modr_total">
            <el-input disabled @blur="modrForm.modr_total = helper.haveFour(modrForm.modr_total)" v-model="modrForm.modr_total" @input="modrForm.modr_total = helper.keepTNum1(modrForm.modr_total)" maxlength="11" show-word-limit placeholder="请填写总金额"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="销售方" prop="modr_cptt_id">
            <el-select v-model="modrForm.modr_cptt_id" placeholder="请选择销售方" clearable>
              <el-option
                  v-for="item in cpttGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="其他金额" prop="extra_price">
            <el-input @change="extraPriceChange" @blur="modrForm.extra_price = helper.haveFour(modrForm.extra_price)" v-model="modrForm.extra_price" @input="modrForm.extra_price = helper.keepTNum1(modrForm.extra_price)" maxlength="11"  show-word-limit placeholder="请填写其他金额"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="备注" prop="modr_remark">
            <el-input v-model="modrForm.modr_remark"  maxlength="50" placeholder="请填写备注"  type="textarea" :rows="1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vg_button">
        <el-button type="primary" plain size="small" @click="addRow()">导入</el-button>
        <el-button type="danger" plain size="small" @click="delBank()">删除</el-button>
      </div>
      <el-row class="vd_mrt">
        <ModrAddMtrl :flag="false" :isShow="false" @handleSelectionChange="handleSelect" :modrForm="modrForm"></ModrAddMtrl>
      </el-row>
      <el-dialog
          :title="title"
          :visible.sync="notice"
          width="70%">
        <ModrAddEditIn @cancelModrIn="cancelModrIn" @confirmIn="confirmIn" ref="ModrAddEditIn"></ModrAddEditIn>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import ModrAddEditIn from "@/views/PurchaseManagement/ModrManage/TabChild/Component/ModrAddEditIn";
import ModrAddMtrl from "@/views/PurchaseManagement/ModrManage/TabChild/Component/ModrAddMtrl";
import {acctAPI} from "@api/modules/acct";
import {cpttAPI} from "@api/modules/comptitle";
import {modrAPI} from "@api/modules/modr";
export default {
  name: "ModrAddMain",
  components: {
    addHeader,
    inputUser,
    ModrAddEditIn,
    ModrAddMtrl,
  },
  data(){
    return{
      modrForm:{
        modr_no:'',
        modr_date:'',
        acct_fctr_id: '',
        acct_fctr_name: '',
        cptt_id: '',
        cptt_name: '',
        modr_total:'',
        modr_remark:'',
        modr_mtrl_list:[],
        key:0,
      },
      rules:{
        modr_no:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        cptt_id:[
          { required: true, trigger: ['change','blur'], message: ' '},
        ],
        modr_cptt_id:[
          { required: true, trigger:['change','blur'], message: ' '},
        ],
        modr_total:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        extra_price:[ {
            pattern: /^\d{0,4}(\.\d{1,4})?$/,
            message: "最大只能输入9999.9999并且只能输入数字且最多4位小数!",   
          },]
      },
      cpttFctrGroupOpt:[],
      cpttGroupOpt:[],
      remove_modr_mtrl_li:[],
      modr_mtrl_li:[],
      notice:false,
      title:'物料信息表',
    }
  },
  created() {
    this.initData()
  },
  methods:{
    initData(){
      this.getCpttPurchaser()
      this.getCpttId()
    },
    // 获取采购方
    getCpttPurchaser(){
      get(cpttAPI.get_my_cptts_v1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttFctrGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    // 获取销售单位id
    getCpttId(flag){
      get(cpttAPI.getAllCpttsV1)
      .then(res=>{
        if(res.data.code === 0){
          this.cpttGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
    },
    //保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      this.modrForm.modr_date = (new Date(this.modrForm.modr_date)).getTime() / 1000;
      let modrForm = {};
      modrForm = Object.assign(modrForm,this.modrForm,staffForm);
      post(modrAPI.addModr,modrForm)
          .then(res => {
            if(res.data.code === 0){
              this.$message({
                type:'success',
                message:'保存成功'
              })
              const permId = this.$route.query.perm_id
              this.jump(`/modr_edit?perm_id=${permId}&form_id=${res.data.data.form_id}`)
              this.resetForm('modrForm')
            }else if(res.data.code === 7){
              this.$message({
                type:'error',
                message:'订单编号重复'
              })
              this.modrForm.modr_date = (new Date(this.modrForm.modr_date)).getTime() * 1000;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
              this.modrForm.modr_date = (new Date(this.modrForm.modr_date)).getTime() * 1000;
            }
          })
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('modrForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      })
    },
    // 其他金额修改影响总金额
    extraPriceChange(){
      let temp = 0;
      if(this.modrForm.modr_mtrl_list.length>0){
        for(let i=0;i<this.modrForm.modr_mtrl_list.length;i++){
          if(this.modrForm.modr_mtrl_list[i].modr_mtrl_num!==undefined && this.modrForm.modr_mtrl_list[i].modr_mtrl_price!==undefined){
            let num1 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_num)*100000
            let num2 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }
      if(this.modrForm.extra_price){
        temp = temp + Number(this.modrForm.extra_price)
      }
      this.modrForm.modr_total = this.helper.haveFour(Number(temp));
    },
    //删除选择的内容
    handleSelect(val){
      this.remove_modr_mtrl_li = val;
    },
    // 删除物料信息按钮
    delBank(){
      if(this.remove_modr_mtrl_li.length<=0){
        return this.$message.warning('至少选择一条数据')
      }
      this.modrForm.modr_mtrl_list = this.modrForm.modr_mtrl_list.filter(item => this.remove_modr_mtrl_li.every(item1 => item.mtrl_id != item1.mtrl_id));
      let temp = 0;
      if(this.modrForm.modr_mtrl_list.length>0){
        for(let i=0;i<this.modrForm.modr_mtrl_list.length;i++){
          if(this.modrForm.modr_mtrl_list[i].modr_mtrl_num!==undefined && this.modrForm.modr_mtrl_list[i].modr_mtrl_price!==undefined){
            let num1 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_num)*100000
            let num2 = Number(this.modrForm.modr_mtrl_list[i].modr_mtrl_price)*100000
            temp = (num1*num2)/10000000000 + temp
          }
        }
      }
      if(this.modrForm.extra_price){
        temp = temp + Number(this.modrForm.extra_price)
      }
      this.modrForm.modr_total = this.helper.haveFour(Number(temp));
    },
    // 打开导入物料信息小窗口
    addRow(){
      this.notice = true;
    },
    // 导入物料信息小窗口确定按钮
    confirmIn(val){
      this.modr_mtrl_li = val;
      this.notice = false;
      this.modr_mtrl_li = this.modr_mtrl_li.filter(item => this.modrForm.modr_mtrl_list.every(item1 => item.mtrl_id != item1.mtrl_id));
      // let modr_mtrl = [];
      // let arr = null;
      // for(let i=0;i<this.modr_mtrl_li.length;i++){
      //   let temp = {};
      //   temp.mtrl_id = this.modr_mtrl_li[i].mtrl_id;
      //   temp.mtrl_no = this.modr_mtrl_li[i].mtrl_no;
      //   temp.mtrl_name = this.modr_mtrl_li[i].mtrl_name;
      //   temp.mtrl_type = this.modr_mtrl_li[i].mtrl_type;
      //   temp.mtrl_spec = this.modr_mtrl_li[i].mtrl_spec;
      //   temp.mtrl_color = this.modr_mtrl_li[i].mtrl_color;
      //   temp.mtrl_weight = this.modr_mtrl_li[i].mtrl_weight;
      //   temp.mtrl_width = this.modr_mtrl_li[i].mtrl_width;
      //   temp.mtrl_unit = this.modr_mtrl_li[i].mtrl_unit;
      //   arr = this.modr_mtrl_li[i].mtrl_id
      //   modr_mtrl.push(temp)
      // }
      for(let i=0;i<this.modr_mtrl_li.length;i++){
        this.modr_mtrl_li[i].mtrl_weight = this.helper.reservedDigits(this.modr_mtrl_li[i].mtrl_weight)
        this.modr_mtrl_li[i].mtrl_width = this.helper.haveFour(this.modr_mtrl_li[i].mtrl_width)
      }
      this.modrForm.modr_mtrl_list = this.modrForm.modr_mtrl_list.concat(this.modr_mtrl_li);
      // console.log('this.modrForm.modr_mtrl_list',this.modrForm.modr_mtrl_list)
      this.$refs.ModrAddEditIn.clear()
    },
    // 取消选择按钮
    cancelModrIn(){
      this.notice = false
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.modrForm.modr_mtrl_list = [];
    },
  }
}
</script>

<style scoped lang="scss">
.vd_mrt{
  margin-top: 20px;
}
.vd_bortop {
  border-top: #DCDFE6 solid 1px;
  padding-top: 20px;
}
</style>