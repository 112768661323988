<template>
  <div class="wrapper">
    <el-form :model="staffForm" ref="staffForm" disabled="disabled" size="mini">
      <el-row type="flex" class="row-bg" justify="end">
        <el-col :md="4">
          <el-form-item label="录入人">
            <el-input v-model="staffForm.staffName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4" v-if="typeFlag === 0">
          <el-form-item label="公司">
            <el-tooltip class="item" effect="dark" :content="staffForm.cpttAbbr" placement="top-start">
              <el-input v-model="staffForm.cpttAbbr"></el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="部门">
            <el-input v-model="staffForm.deptName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="小组">
            <el-input v-model="staffForm.deptTeamName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "inputUser",
  props:{
    isCookie:Boolean,
    stffForm:Object,
    typeFlag:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      staffForm:{
        staffName:'',
        deptName:'',
        deptTeamName:'',
      },
      userInfo: {},
      disabled:true,
    }
  },
  created() {
    this.initData()
  },
  mounted(){

  },
  watch:{
    // 'this.stffForm': {
    //   immediate: true,
    //   handler: function(newVal) {
    //     console.log('newVal',newVal)
    //   }
    // }
    // 'staffForm': function (newVal){
    //   if(newVal){
    //     this.staffForm.staffName = 'wwwww'
    //     console.log('newVal',newVal)
    //   }
    // },
  },
  methods:{
    initData(){
      // this.staffForm = this.stffForm
      this.getStaffInput()
    },
    getStaffInput(){
      if(this.isCookie){
        this.userInfo = this.$cookies.get('userInfo')
        this.staffForm.staffName = this.userInfo.stff_name
        this.staffForm.cpttAbbr = this.userInfo.cptt_abbr;
        this.staffForm.deptName = this.userInfo.dept_name
        this.staffForm.deptTeamName = this.userInfo.dept_team_name
      } else if(!this.isCookie) {
        setTimeout(()=>{
          this.staffForm.staffName = this.stffForm.stff_name;
          this.staffForm.deptName = this.stffForm.dept_name;
          this.staffForm.cpttAbbr = this.stffForm.cptt_abbr;
          this.staffForm.deptTeamName = this.stffForm.dept_team_name;
        },200)
      }
    },
    getData(){
      const staffInfoIds = {
        user_id: this.userInfo.user_id,
        stff_id: this.userInfo.stff_id,
        dept_id: this.userInfo.dept_id,
        dept_team_id: this.userInfo.dept_team_id
      }
      return staffInfoIds
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper ::v-deep .el-input{
  width: 125px;
}
.wrapper ::v-deep .el-form-item__label{
  width: 78px;
  color: $color-info;
}
</style>